import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import style from "../scss/templates/blog-post.module.scss";
import BlogPostTableOfContents from "../components/BlogPostTableOfContents";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  tableOfContents,
  showTableOfContents,
  featuredimage,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section blog-post">
      {helmet || ""}
      <div className="content">
        <div className={`columns ${style.blog_post}`}>
          <div className="column is-10 is-offset-1">
            <h1
              className={`${style.blog_post__title} title is-size-2 has-text-weight-bold is-bold-light`}
            >
              {title}
            </h1>
            <div>
              {featuredimage && (
                <img
                  className={style.blog_post__thumbnail}
                  src={featuredimage.childImageSharp.fluid.src}
                />
              )}
            </div>
            {showTableOfContents && tableOfContents && (
              <BlogPostTableOfContents tableOfContents={tableOfContents} />
            )}
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  tableOfContents: PropTypes.string,
  showTableOfContents: PropTypes.bool,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const siteMetadata = data.site.siteMetadata;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        tableOfContents={post.tableOfContents}
        helmet={
          <Helmet titleTemplate={`%s | ${siteMetadata.title}`}>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        showTableOfContents={post.frontmatter.showTableOfContents}
        featuredimage={post.frontmatter.featuredimage}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        showTableOfContents
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      tableOfContents
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
