import { Link } from "gatsby";
import React from "react";
import style from "../scss/templates/blog-post.module.scss";
const BlogPostTableOfContents = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }
  render() {
    const tableOfContents = this.props.tableOfContents;
    return (
      <div className={style.blog_post__table_of_contents}>
        <div
          className={style.blog_post__table_of_contents__header}
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <span className={style.blog_post__table_of_contents__header__icon}>
            {this.state.isOpen ? "▼" : "▶"}
          </span>
          <span>目次</span>
        </div>
        {this.state.isOpen && (
          <div dangerouslySetInnerHTML={{ __html: tableOfContents }}></div>
        )}
      </div>
    );
  }
};
export default BlogPostTableOfContents;
